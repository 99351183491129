import {AuthConfig} from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {
    issuer: 'https://identity.nivusweb.com',
    redirectUri: document.baseURI + 'index.html',
    silentRefreshRedirectUri: document.baseURI + 'silent-refresh.html',
    clientId: 'Nivus.DataKiosk.Frontend',
    scope: 'openid profile email roles offline_access device.read',
    postLogoutRedirectUri: document.baseURI + 'index.html',
    responseType: 'code',
    showDebugInformation: false,
    sessionChecksEnabled: false,
    preserveRequestedRoute: true
};
