import {Base, Unit} from './common';
import {Observable} from 'rxjs';

/* eslint @typescript-eslint/naming-convention: "off" */
export interface Station extends Base<string> {
    ProjectId: string;
    Channels: Channel[];
}

/* eslint @typescript-eslint/naming-convention: "off" */
export interface Channel extends Base<string> {
    Unit: Unit;
    DecimalPlaces: number;
    UnitId: string;
    ShortName: string;
    ExchangeName: string;
}

export abstract class StationsData {
    abstract getStations(): Observable<Station[]>;

    abstract getStation(id: string): Observable<Station>;
    abstract getAvailableShortnames(): Observable<string[]>;
}
