import {Component} from '@angular/core';
import {AuthService} from "./auth/auth.service";

@Component({
    selector: 'nivus-datakiosk-app',
    template: '<router-outlet></router-outlet>'
})
export class AppComponent {
    title = 'Nivus-DataKiosk-Frontend';

    constructor(private authService: AuthService) {
        this.authService.login();
    }
}
