import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Station, StationsData } from '../model/stations';
import {BaseService} from './base.service';
import {DatakioskHttpService} from '../datakiosk-http.service';

@Injectable({providedIn: 'root'})
export class StationsService extends BaseService implements StationsData {
    constructor(http: DatakioskHttpService) {
        super(http);
    }

    getStations(): Observable<Station[]> {
        return this.http.get(this.apiEndpoint);
    }

    getStation(id: string): Observable<Station> {
        return this.http.get(`${this.apiEndpoint}/${id}`, id);
    }

    getAvailableShortnames(): Observable<string[]> {
        return this.http.get(`${this.apiEndpoint}/shortnames`);
    }

    get apiEndpoint(): string {
        return 'v2/stations';
    }

}
