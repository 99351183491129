<mat-nav-list>
    <!--<mat-list-item routerLinkActive="selected" group="dashboard" (click)="scrollToTop()" disabled>
        <a class="" [routerLink]="['/', 'dashboard']">
            <mat-icon>home</mat-icon>
            <span fxFlex>{{ 'DASHBOARD' | translate }}</span>
        </a>
    </mat-list-item>
    <mat-list-item>
        <hr />
    </mat-list-item>
    -->
    <mat-list-item routerLinkActive="selected" group="projects" (click)="scrollToTop()">
        <a class="" [routerLink]="['/', 'projects', 'none', 'stations', 'all']">
            <mat-icon>analytics</mat-icon>
            <span fxFlex>{{ 'PROJECTS' | translate }}</span>
        </a>
    </mat-list-item>
    <mat-list-item routerLinkActive="selected" group="configurations" (click)="scrollToTop()">
        <a class="" [routerLink]="['/', 'configurations']">
            <mat-icon>description</mat-icon>
            <span fxFlex>{{ 'CONFIGURATIONS' | translate }}</span>
        </a>
    </mat-list-item>
    <mat-list-item routerLinkActive="selected" group="clients" (click)="scrollToTop()">
        <a class="" [routerLink]="['/', 'clients']">
            <mat-icon>output</mat-icon>
            <span fxFlex>{{ 'CLIENTS' | translate }}</span>
        </a>
    </mat-list-item>
    <mat-list-item routerLinkActive="selected" group="jobs" (click)="scrollToTop()">
        <a class="" [routerLink]="['/', 'jobs']">
            <mat-icon>event_repeat</mat-icon>
            <span fxFlex>{{ 'JOBS' | translate }}</span>
        </a>
    </mat-list-item>
    <hr />
    <mat-list-item routerLinkActive="selected" group="api" (click)="scrollToTop()">
        <a class="" [routerLink]="['/', 'description']">
            <mat-icon>api</mat-icon>
            <span fxFlex>{{ 'API' | translate }}</span>
        </a>
    </mat-list-item>
</mat-nav-list>
