import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from './base.service';
import {DatakioskHttpService} from '../datakiosk-http.service';
import {Application, ApplicationsData} from "../model/applications";

@Injectable({providedIn: 'root'})
export class ApplicationsService extends BaseService implements ApplicationsData {
    constructor(http: DatakioskHttpService) {
        super(http);
    }

    get apiEndpoint(): string {
        return 'v2/clients/applications';
    }

    getApplications(): Observable<Application[]> {
        return this.http.get(this.apiEndpoint);
    }

    uploadApplication(file: File): Observable<Application> {
        const formData = new FormData();
        formData.append("file", file);
        return this.http.post(`${this.apiEndpoint}`, formData);
    }

    downloadFile(id: string) {
        window.location.href = this.http.apiUrl + '/' + this.apiEndpoint + '/' + id;
    }
}
