import {Base} from './common';
import {Observable} from 'rxjs';



export interface Job extends Base<string> {
    Active: boolean,
    ConfigurationId: string,
    ClientId: string,
    Output: string,
    PathPattern: string,
    CloudSettings: string,
    CustomerId: string,
    TriggerTime: string,
    TriggerInterval: string,
    LastRunDateTime: string | null,
    LastRunSuccessfully: boolean,
    ProjectId: string,
    Stations: JobStation[]
}

export interface JobStation {
    Id?: number,
    StationId: string,
    JobId: string,
    LastExportDateTime?: string,
    ExportedChannels?: string
}
export interface JobExecution extends Base<string> {
    JobId: string;
    CustomerId: string;
    ExecutionTime: string;
    Success: boolean;
    JobExecutionLogs: JobExecutionLog[]
}

export interface JobExecutionLog {
    Time: string;
    Severity: Severity;
    Text: string;
    JobExecutionId: string;
}

export enum Severity {
    error = 'Error',
    warn = 'Warn',
    info = 'Info',
    debug = 'Debug'
}

export abstract class JobsData {
    abstract getJobs(): Observable<Job[]>;
    abstract getJob(id: string): Observable<Job>;
    abstract addJob(job: Job): Observable<Job>;
    abstract updateJob(id: string, job: Job): Observable<boolean>;
    abstract deleteJob(id: string) : Observable<boolean>;
    abstract getJobExecutions(jobId: string, amount: number, offset: number): Observable<JobExecution[]>;

}
