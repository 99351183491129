import {Base} from './common';
import {Observable} from 'rxjs';

export interface Configuration extends Base<string> {
    Type: string;
    CustomerId: string;
    Settings: string;
}

export abstract class ConfigurationsData {
    abstract getConfigurations(): Observable<Configuration[]>;
    abstract getConfigurationById(id: string) : Observable<Configuration>;
    abstract addConfiguration(config: Configuration): Observable<Configuration>;
    abstract updateConfiguration(id: string, config: Configuration): Observable<boolean>;
    abstract deleteConfiguration(id: string): Observable<boolean>;
}
