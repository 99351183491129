<div class="main-container nivus" dir="ltr">
    <mat-toolbar color="primary" class="topbar relative" fxLayout="row" fxLayoutAlign="start center">
        <div class="navbar-header">
            <a class="navbar-brand text-white" href="/">
                <img src="assets/icon.png" alt="homepage" width="36" class="light-logo m-r-5">
                <span><b>NIVUS</b>DataKiosk</span>
            </a>
        </div>
        <button mat-icon-button (click)="snav.toggle()" class="sidebar-toggle" fxHide.gt-xs
                value="sidebarclosed">
            <mat-icon>menu</mat-icon>
        </button>
        <span fxFlex></span>
        <nivus-vertical-header></nivus-vertical-header>
    </mat-toolbar>
    <mat-sidenav-container class="example-sidenav-container oh m-t-0" [class.minisidebar]="minisidebar">
        <mat-sidenav #snav id="snav" class="pl-xs" [mode]="(isHandset$ | async) ? 'over' : 'side'" fixedTopGap="0"
                     [opened]="(isHandset$ | async) === false" [disableClose]="(isHandset$ | async) === false"
                     (open)="sidebarOpened = true"
                     (close)="sidebarOpened = false">

            <perfect-scrollbar class="scbar" [config]="config">
                <nivus-vertical-sidebar class="app-sidebar">
                </nivus-vertical-sidebar>
            </perfect-scrollbar>
            <button mat-icon-button style="position: absolute; bottom: 0; right: 0;" (click)="toggleMinisidebar()" fxHide.lt-sm>
                <mat-icon class="text-muted">{{minisidebar ? 'keyboard_double_arrow_right' : 'keyboard_double_arrow_left'}}</mat-icon>
            </button>
        </mat-sidenav>
        <mat-sidenav-content class="page-wrapper oh" fxLayout="column" fxLayoutAlign="start stretch">
            <nivus-breadcrumb></nivus-breadcrumb>
            <div class="page-content">
                <router-outlet></router-outlet>
            </div>
            <div class="version">
                {{version}}
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
