import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Router} from '@angular/router';
import {Component} from '@angular/core';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {Observable} from "rxjs";
import {map, shareReplay} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {getCookie, setCookie} from "typescript-cookie";

/** @title Responsive sidenav */
@Component({
    selector: 'nivus-full-layout',
    templateUrl: 'full.component.html',
    styleUrls: ['full.component.scss']
})
export class FullComponent {
    isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.XSmall])
        .pipe(
            map(result => result.matches),
            shareReplay()
        );

    minisidebar = false;
    boxed = false;
    horizontal = false;

    green = false;
    blue = false;
    danger = false;
    url = '';
    sidebarOpened = false;

    version = environment.version;
    public config: PerfectScrollbarConfigInterface = {};

    constructor(
        private breakpointObserver: BreakpointObserver,
        public router: Router
    ) {
        this.minisidebar = getCookie('Sidebar') !== 'false';
        setCookie('Sidebar', this.minisidebar, {path: '/', expires: 365});
    }

    toggleMinisidebar() {
        this.minisidebar = !this.minisidebar;
        setCookie('Sidebar', this.minisidebar, {path: '/', expires: 365});
    }
}
