import {Observable} from 'rxjs';

/* eslint @typescript-eslint/naming-convention: "off" */
export interface DataReadRequest {
    PvId: string;
    Values: ValueRead[];
}

/* eslint @typescript-eslint/naming-convention: "off" */
export interface DataWriteRequest {
    PvId: string;
    Values: DataWriteValue[] | DataWriteCalcValue[];
}

/* eslint @typescript-eslint/naming-convention: "off" */
export interface DataWriteValue {
    ValTime: string;
    Val: number;
}

/* eslint @typescript-eslint/naming-convention: "off" */
export interface DataWriteCalcValue {
    ValTime: string;
    CalcVal: number;
    CalcFunction: number;
    Label: number;
}

/* eslint @typescript-eslint/naming-convention: "off" */
export interface ValueRead extends DataWriteValue, DataWriteCalcValue {
    Reason: number;
    Identifier: number;
    RawVal: number;
    RawIdentifier: number;
    ScaledVal: number;
}

export abstract class DataData {
    abstract getData(pvIds: string[], from: Date, to: Date): Observable<DataReadRequest[]>;

    abstract updateData(field: string, values: DataWriteRequest[]): Observable<string[]>;
}
