import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {Job, JobExecution, JobsData} from '../model/jobs';
import {Observable} from 'rxjs';
import {DatakioskHttpService} from '../datakiosk-http.service';


@Injectable({providedIn: 'root'})
export class JobsService extends BaseService implements JobsData {
    constructor(http: DatakioskHttpService) {
        super(http);
    }

    addJob(job: Job): Observable<Job> {
        return this.http.post(this.apiEndpoint, job);
    }

    get apiEndpoint(): string {
        return 'v2/jobs';
    }

    getJobs(): Observable<Job[]> {
        return this.http.get(this.apiEndpoint);
    }

    updateJob(id: string, job: Job): Observable<boolean> {
        return this.http.put(`${this.apiEndpoint}/${id}`, job);
    }

    getJob(id: string): Observable<Job> {
        return this.http.get(`${this.apiEndpoint}/${id}`);
    }

    deleteJob(id: string): Observable<boolean> {
        return this.http.delete(`${this.apiEndpoint}/${id}`);
    }

    getJobExecutions(jobId: string, amount: number, offset: number): Observable<JobExecution[]> {
        return this.http.get(`${this.apiEndpoint}/${jobId}/executions?amount=${amount}&offset=${offset}`);
    }
}
