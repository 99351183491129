import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataData, DataReadRequest, DataWriteRequest } from '../model/data';
import {BaseService} from './base.service';
import {DatakioskHttpService} from '../datakiosk-http.service';

@Injectable()
export class DataService extends BaseService implements  DataData{
    constructor(http: DatakioskHttpService) {
        super(http);
    }

    getData(pvIds: string[], from: Date, to: Date): Observable<DataReadRequest[]> {
        return this.http.post(this.apiEndpoint, {From: from, To: to, PvIds: pvIds});
    }

    updateData(field: string, values: DataWriteRequest[]): Observable<string[]> {
        return this.http.put(`${this.apiEndpoint}/${field.toLowerCase()}`, values);
    }

    get apiEndpoint(): string {
        return 'v1/channels';
    }
}
