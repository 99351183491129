import {Base, Unit} from './common';
import {Observable} from 'rxjs';

/* eslint @typescript-eslint/naming-convention: "off" */
export interface Data {
    ValTime: string;
    Val: number;
    ValDisplay: string;
    CalcVal: number;
    CalcValDisplay: string;
    ScaledVal: number;
    ScaledValDisplay: string;
    Reason: number;
    Identifier: number;
}

/* eslint @typescript-eslint/naming-convention: "off" */
export interface ChannelData extends Base<string> {
    Data: Data[];
    Unit: Unit;
    DecimalPlaces: number;
    UnitId: string;
}

export abstract class ExportData {
    abstract getData(stationId: string, from: Date, to: Date): Observable<ChannelData[]>;

    abstract getCsv(stationId: string, from: Date, to: Date, pvIds: string[],
                    utcOffset: number, culture: string): Observable<Blob>;

    abstract getExport(configurationId: string, projectId: string, mpIds: string[], from: Date, to: Date): Observable<any>;

    abstract getCultures(): Observable<string[]>;
}
