import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class DatakioskHttpService extends HttpService {
    constructor(service: HttpClient) {
        super(service);
    }
    get apiUrl(): string {
        return environment.datakioskUrl;
    }
}
