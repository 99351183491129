import {CommonModule} from '@angular/common';
import {NgModule, ModuleWithProviders} from '@angular/core';
import {ProjectsService} from './services/projects.service';
import {DatakioskHttpService} from './datakiosk-http.service';
import {ProjectsData} from './model/projects';
import {StationsData} from './model/stations';
import {StationsService} from './services/stations.service';
import {HttpClientModule} from '@angular/common/http';
import {ExportData} from './model/export';
import {ExportService} from './services/export.service';
import {DataData} from './model/data';
import {DataService} from './services/data.service';
import {ClientsData} from './model/clients';
import {ClientsService} from './services/clients.service';
import {ConfigurationsData} from './model/configurations';
import {ConfigurationsService} from './services/configurations.service';
import {UsersService} from './services/user.service';
import {UsersData} from './model/users';
import {JobsService} from './services/jobs.service';
import {JobsData} from './model/jobs';
import {ApplicationsData} from "./model/applications";
import {ApplicationsService} from "./services/applications.service";

const API = [
    DatakioskHttpService
];

const SERVICES = [
    {provide: ProjectsData, useClass: ProjectsService},
    {provide: StationsData, useClass: StationsService},
    {provide: ExportData, useClass: ExportService},
    {provide: DataData, useClass: DataService},
    {provide: ClientsData, useClass: ClientsService},
    {provide: ConfigurationsData, useClass: ConfigurationsService},
    {provide: UsersData, useClass: UsersService},
    {provide: JobsData, useClass: JobsService},
    {provide: ApplicationsData, useClass: ApplicationsService}
];

@NgModule({
    imports: [
        HttpClientModule,
        CommonModule
    ],
})
export class DatakioskBackendModule {
    static forRoot(): ModuleWithProviders<DatakioskBackendModule> {
        return {
            ngModule: DatakioskBackendModule,
            providers: [
                ...API,
                ...SERVICES,
            ],
        };
    }
}
