<mat-toolbar color="primary" class="topbar relative">
    <div class="w-100 fix-width-for-horizontal">
        <div class="navbar-header">
            <a class="navbar-brand text-white" href="/">
                <b>
                    <img src="assets/icon.png" alt="homepage" width="36" class="light-logo">
                </b>
                <span><b>NIVUS</b>DataKiosk</span>
            </a>
        </div>
        <span fxFlex></span>
        <nivus-vertical-header></nivus-vertical-header>
    </div>
</mat-toolbar>
<div class="unauthorized">
    <h1>{{ 'UNAUTHORIZED' | translate }}</h1>
    <a href="#" style="text-decoration: underline" (click)="authService.logout()">{{ 'BACK_TO_LOGIN' | translate }}</a>
</div>

