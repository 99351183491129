import {Injectable} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay, startWith, switchMap} from 'rxjs/operators';

import {authConfig} from './auth.config';
import {Router} from "@angular/router";
import {UserRole} from "../shared/backend/datakiosk/model/users";
import {CurrentUserService} from "../shared/components/role-select/current-user.service";

@Injectable()
export class AuthService {
    private authenticationChange: Subject<boolean> = new ReplaySubject<boolean>(1);
    private userChange: Subject<any> = new ReplaySubject<any>(1);

    currentUserRole$: Observable<UserRole>;
    isLoggedIn$: Observable<boolean>;
    claims$: Observable<Claims>;
    currentUserEmail$: Observable<string>;
    isServiceUser$: Observable<boolean>;

    constructor(private authService: OAuthService, private router: Router, private usersService: CurrentUserService) {
        this.currentUserRole$ = usersService.selectedRole.pipe(distinctUntilChanged());
        this.isLoggedIn$ = this.authService.events
            .pipe(
                filter(event => event.type === 'token_received'),
                map(event => this.authService.hasValidAccessToken()),
                startWith(this.authService.hasValidAccessToken()),
                shareReplay(1)
            );
        this.isLoggedIn$.subscribe();
        this.claims$ = this.authService.events
            .pipe(
                filter(event => event.type === 'user_profile_loaded'),
                map(event => this.authService.getIdentityClaims() as Claims),
                shareReplay(1)
            );
        this.claims$.subscribe();
        this.currentUserEmail$ = this.claims$
            .pipe(
                map(claims => claims.email),
                shareReplay(1)
            );
        this.isServiceUser$ = this.claims$
            .pipe(
                map(claims => claims.role?.indexOf("ServiceUser") > -1),
                shareReplay(1)
            );

        this.authenticationChange.next(this.authService.hasValidAccessToken());
        this.authService.events.pipe(
            filter(event => event.type === 'token_received')
        ).subscribe(_ => this.authenticationChange.next(this.authService.hasValidAccessToken()));
        this.authService.events.pipe(
            filter(event => event.type === 'user_profile_loaded')
        ).subscribe(_ => this.userChange.next(this.authService.getIdentityClaims()));
    }

    onAuthenticationChange(): Observable<boolean> {
        return this.authenticationChange.asObservable();
    }

    onUserChange(): Observable<any> {
        return this.userChange.asObservable();
    }

    login() {

        if (authConfig.showDebugInformation) {
            console.debug = console.log;
        }
        this.authService.configure(authConfig);
        this.authService.loadDiscoveryDocumentAndLogin().then(_ => {
            this.router.initialNavigation();
            if (this.authService.hasValidAccessToken()) {
                this.authService.loadUserProfile();
            }
            this.authService.setupAutomaticSilentRefresh();
        });
        this.authService.events.subscribe(event => {
            if (event.type == 'session_terminated') {
                this.authService.revokeTokenAndLogout().then(_ => window.location.reload());
            }
            if (event.type == 'token_received' && this.authService.hasValidAccessToken()) {
                this.authService.loadUserProfile();
            }
        });
    }

    logout() {
        this.authService.revokeTokenAndLogout();
    }
}

export interface Claims {
    email: string;
    role: string[];
}
