import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from './base.service';
import {DatakioskHttpService} from '../datakiosk-http.service';
import {Client, ClientCreate, ClientsData} from '../model/clients';

@Injectable({providedIn: 'root'})
export class ClientsService extends BaseService implements  ClientsData {
    constructor(http: DatakioskHttpService) {
        super(http);
    }

    get apiEndpoint(): string {
        return 'v2/clients';
    }

    getClients(): Observable<Client[]> {
        return this.http.get(this.apiEndpoint);
    }

    addClient(client: ClientCreate): Observable<Client> {
        return this.http.post(this.apiEndpoint, client);
    }

    updateClient(id: string, client: Client): Observable<boolean> {
        return this.http.put(`${this.apiEndpoint}/${id}`, client);
    }

    getClientById(id: string): Observable<Client> {
        return this.http.get(`${this.apiEndpoint}/${id}`);
    }

    deleteClient(id: string): Observable<boolean> {
        return this.http.delete(`${this.apiEndpoint}/${id}`);
    }

    resetAppId(id: string): Observable<boolean> {
        return this.http.delete(`${this.apiEndpoint}/${id}/application`);
    }
}
