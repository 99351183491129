import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from './base.service';
import {DatakioskHttpService} from '../datakiosk-http.service';
import {Configuration, ConfigurationsData} from '../model/configurations';

@Injectable({providedIn: 'root'})
export class ConfigurationsService extends BaseService implements  ConfigurationsData {
    getConfigurationById(id: string): Observable<Configuration> {
        return this.http.get(`${this.apiEndpoint}/${id}`);
    }
    constructor(http: DatakioskHttpService) {
        super(http);
    }

    get apiEndpoint(): string {
        return 'v2/configurations';
    }

    getConfigurations(): Observable<Configuration[]> {
        return this.http.get(this.apiEndpoint);
    }

    addConfiguration(config: Configuration): Observable<Configuration> {
        return this.http.post(this.apiEndpoint, config);
    }

    updateConfiguration(id: string, config: Configuration): Observable<boolean> {
        return this.http.put(`${this.apiEndpoint}/${id}`, config);
    }

    deleteConfiguration(id: string): Observable<boolean> {
        return this.http.delete(`${this.apiEndpoint}/${id}`);
    }

}
