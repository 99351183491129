import {
    ChangeDetectorRef,
    Component,
    OnDestroy
} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {AuthService} from "../../../auth/auth.service";

@Component({
    selector: 'nivus-vertical-sidebar',
    templateUrl: './vertical-sidebar.component.html',
    styleUrls: []
})

export class VerticalSidebarComponent implements OnDestroy {
    mobileQuery: MediaQueryList;

    private _mobileQueryListener: () => void;

    scrollToTop() {
        document.querySelector('.page-wrapper')?.scroll({
            top: 0,
            left: 0
        });
    }

    constructor(
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        public authService: AuthService
    ) {
        this.mobileQuery = media.matchMedia('(min-width: 768px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        // tslint:disable-next-line: deprecation
        this.mobileQuery.addListener(this._mobileQueryListener);
    }

    ngOnDestroy(): void {
        // tslint:disable-next-line: deprecation
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }
}
