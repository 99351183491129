import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Unit} from '../model/common';
import {ChannelData, ExportData} from '../model/export';
import {BaseService} from './base.service';
import {DatakioskHttpService} from '../datakiosk-http.service';

@Injectable()
export class ExportService extends BaseService implements ExportData {

    constructor(http: DatakioskHttpService) {
        super(http);
    }

    getData(stationId: string, from: Date, to: Date): Observable<ChannelData[]> {
        return this.http.post(`${this.apiEndpoint}/${stationId}`, {params: {from, to}}).pipe(
            map(result => result[stationId]),
            tap(result => {
                result.forEach((channel: ChannelData) => channel.Data.forEach(value => {
                    value.ValDisplay = this.convertValue(value.Val, channel.Unit, channel.DecimalPlaces);
                    if (value.CalcVal !== undefined) {
                        value.CalcValDisplay = this.convertValue(value.CalcVal, channel.Unit, channel.DecimalPlaces);
                    }
                    if (value.ScaledVal !== undefined) {
                        value.ScaledValDisplay = this.convertValue(value.ScaledVal, channel.Unit, channel.DecimalPlaces);
                    }
                }));
            })
        );
    }

    convertValue(value: number, unit: Unit, decimalPlaces: number): string {
        if (!unit) {
            return value.toFixed(decimalPlaces);
        }
        return (value * unit.Factor + unit.Constant).toFixed(decimalPlaces);
    }

    getCsv(stationId: string, from: Date, to: Date, pvIds: string[], utcOffset: number, culture: string): Observable<Blob> {
        return this.http.post(
            `${this.apiEndpoint}/${stationId}/csv`,
            {
                params: {
                    from,
                    to,
                    pvIds: pvIds ? pvIds : undefined,
                    timeZoneOffset: utcOffset,
                    culture
                },
                responseType: 'blob'
            }
        );
    }


    getExport(configurationId: string, projectId: string, mpIds: string[], from: Date, to: Date): Observable<any> {
        return this.http.post(`${this.apiEndpoint}/configuration/${configurationId}`,
            {
                projectId: projectId,
                configurationId: configurationId,
                stationIds: mpIds ? mpIds : undefined,
                from: from.toString(),
                to: to.toString()
            },
            {
                responseType: 'blob',
                observe: 'response'
            });
    }

    getCultures(): Observable<string[]> {
        return this.http.get(`${this.apiEndpoint}/cultures`);
    }

    get apiEndpoint(): string {
        return 'v2/export';
    }

}
