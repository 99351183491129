import {Base} from './common';
import {Observable} from 'rxjs';

export interface Application extends Base<string> {
    Type: string;
    Version: string;
    Os: string;
    FileName: string;
    Checksum: string;
}

export abstract class ApplicationsData {
    abstract getApplications(): Observable<Application[]>;
    abstract uploadApplication(file: File): Observable<Application>;
    abstract downloadFile(id: string): void;
}
