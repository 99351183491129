import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {OAuthModule} from 'angular-oauth2-oidc';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {DatakioskBackendModule} from './shared/backend/datakiosk/datakiosk-backend.module';
import {AuthService} from './auth/auth.service';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface} from "ngx-perfect-scrollbar";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import { LayoutModule } from './layouts/layout.module';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {CustomerHeaderInterceptor} from "./auth/customer-header.interceptor";

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + environment.version);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 2,
    wheelPropagation: true
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        MatMomentDateModule,
        DatakioskBackendModule.forRoot(),
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [environment.datakioskUrl],
                sendAccessToken: true
            }
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        LayoutModule
    ],
    providers: [
        AuthService,
        [
            {
                provide: HTTP_INTERCEPTORS,
                useClass: CustomerHeaderInterceptor,
                multi: true
            },
            {
                provide: PERFECT_SCROLLBAR_CONFIG,
                useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
            }
        ]
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
