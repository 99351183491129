import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router, NavigationEnd, ActivatedRoute, Data} from '@angular/router';
import {filter, map, mergeMap, tap} from 'rxjs/operators';
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../../../auth/auth.service";

@Component({
    selector: 'nivus-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: []
})
export class BreadcrumbComponent {
    pageInfo: Data = Object.create(null);
    helpPage = "";

    constructor(
        public authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private translate: TranslateService
    ) {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map(route => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }),
            filter(route => route.outlet === 'primary'),
            mergeMap(route => route.data),
            tap(data => this.pageInfo = data),
            map(data => ['DATAKIOSK', ...data.title]),
            mergeMap(data => this.translate.getStreamOnTranslationChange(data).pipe(
                map(translations => data.map((element: string) => translations[element]).join(' > '))))
        )
            .subscribe(title => {
                this.titleService.setTitle(title);
            });
    }

    openHelp() {
        const lang = (this.translate.currentLang || this.translate.defaultLang) != 'de' ? 'en' : 'de';
        const path = typeof this.pageInfo.help === 'object' ? this.pageInfo.help[lang] : this.pageInfo.help;
        window.open(`https://help.nivusweb.com/${lang}/ndk/${path}`, '_blank');
    }
}
