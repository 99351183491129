// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    datakioskUrl: 'https://datakiosk-api.nivusweb.com/api',
    version: 'Dev',
    dateTimeFormat: 'MM/dd/yyyy, HH:mm:ss (z)'
};
