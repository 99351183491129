import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Project, ProjectsData } from '../model/projects';
import {BaseService} from './base.service';
import {DatakioskHttpService} from '../datakiosk-http.service';

@Injectable({providedIn: 'root'})
export class ProjectsService extends BaseService implements ProjectsData{
    constructor(http: DatakioskHttpService) {
        super(http);
    }

    getProjects(): Observable<Project[]> {
        return this.http.get(this.apiEndpoint);
    }

    get apiEndpoint(): string {
        return 'v1/projects';
    }
}
