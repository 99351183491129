import {OnInit, Component} from '@angular/core';
import {CurrentUserService} from './current-user.service';
import {Observable} from "rxjs";
import {map, shareReplay, tap} from "rxjs/operators";
import {getCookie, setCookie} from 'typescript-cookie'
import {UserRole, UserType} from "../../backend/datakiosk/model/users";

@Component({
    selector: 'nivus-role-select',
    styleUrls: ['./role-select.component.scss'],
    templateUrl: './role-select.component.html',
})
export class RoleSelectComponent implements OnInit {
    filterValue: string;

    roles$: Observable<UserRole[]>;
    selectedRole: UserRole;

    constructor(private currentUser: CurrentUserService) {
    }

    ngOnInit(): void {
        this.roles$ = this.currentUser.user
            .pipe(
                map(user => user?.Roles ?? []),
                tap(roles => roles.sort(this.byDepthAndName)),
                tap(roles => this.initialSelect(roles)),
                shareReplay(1)
            );
        this.currentUser.refresh();
    }

    byDepthAndName(a: UserRole, b: UserRole) {
        const aCustomerDepth = a.CustomerId.split('_').length;
        const bCustomerDepth = b.CustomerId.split('_').length;

        if (aCustomerDepth === bCustomerDepth) {
            return a?.CustomerName ? a.CustomerName.localeCompare(b?.CustomerName) : -1;
        } else {
            return aCustomerDepth < bCustomerDepth ? -1 : 1;
        }
    }

    initialSelect(roles: UserRole[]) {
        if (roles?.length > 0) {
            const customer = getCookie('Customer')?.split('|');
            const role = roles.find(r =>
                !customer
                || customer.length < 2
                || (r.CustomerId === customer[0] && r.UserType === customer[1]));
            this.selectRole(role || roles[0]);
        }
    }

    selectRole(role: UserRole) {
        this.filterValue = '';
        this.selectedRole = role;
        this.currentUser.selectedRole.next(role);
        setCookie('Customer', `${role.CustomerId}|${role.UserType}`);
    }

    stopPropagation($event: any) {
        $event.stopPropagation();
    }



    getColor(userType: UserType): string {
        switch(userType) {
            case UserType.user:
            case UserType.customerService:
                return 'primary';
            case UserType.admin:
            case UserType.supervisor:
                return 'accent';
            default:
                return 'info';
        }
    }
}
