import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RoleSelectComponent} from './role-select.component';
import {MaterialModule} from "../../../material-module";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Ng2SearchPipeModule} from "ng2-search-filter";

@NgModule({
    declarations: [
        RoleSelectComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        Ng2SearchPipeModule
    ],
    exports: [
        RoleSelectComponent
    ]
})
export class RoleSelectModule {
}
