import {Base} from './common';
import {Observable} from 'rxjs';
import {CloudTargetType} from "./cloudsettings";
import {Job} from "./jobs";

export interface Client extends Base<string> {
    Outputs: string;
    ApiKey: string;
    LastCommunication: Date;
    Type: string;
    Version: string;
    CustomerId: string;
    OutputConfigurations: OutputConfiguration[];
    State: ClientState;
    ApplicationId: string;
    Jobs: Job[];
}

export interface OutputConfiguration extends Base<string> {
    Type: OutputType;
    Config: string;
}

export enum OutputType {
    Filesystem = 'Filesystem',
    Ftp = 'Ftp',
    SFtp = 'SFtp',
    EMail = 'EMail',
    Mqtt = 'Mqtt'
}

export enum ClientState {
    Running = 'Running',
    Stopped = 'Stopped',
    InvalidType = 'InvalidType',
}

export interface ClientCreate {
    Name: string;
    CustomerId: string;
    Type?: string;
}

export abstract class ClientsData {
    abstract getClients(): Observable<Client[]>;
    abstract getClientById(id: string) : Observable<Client>;
    abstract addClient(client: ClientCreate): Observable<Client>;
    abstract updateClient(id: string, client: Client): Observable<boolean>;
    abstract deleteClient(id: string):Observable<boolean>;
    abstract resetAppId(id: string):Observable<boolean>;
}
