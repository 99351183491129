import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export abstract class HttpService {
    abstract get apiUrl(): string;

    constructor(protected client: HttpClient) {
    }

    get(endpoint: string, options?: any): Observable<any> {
        return this.client.get(`${this.apiUrl}/${endpoint}`, options);
    }

    post(endpoint: string, data: any, options?: any): Observable<any> {
        return this.client.post(`${this.apiUrl}/${endpoint}`, data, options);
    }

    put(endpoint: string, data: any, options?: any): Observable<any> {
        return this.client.put(`${this.apiUrl}/${endpoint}`, data, options);
    }

    delete(endpoint: string, options?: any): Observable<any> {
        return this.client.delete(`${this.apiUrl}/${endpoint}`, options);
    }
}
