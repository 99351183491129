import {Base} from './common';
import {Observable} from 'rxjs';

export interface User extends Base<string> {
    Email: string;
    Roles: UserRole[];
}

export interface UserRole {
    UserId: number;
    CustomerId: string;
    CustomerName: string;
    UserType: UserType
}

export enum UserType {
    guest = 'Guest',
    user= 'User',
    admin= 'Admin',
    customerService = 'CustomerService',
    supervisor= 'Supervisor',
}

export abstract class UsersData {
    abstract getCurrentUser(): Observable<User>;
    abstract getApiKey(): Observable<string>;
}
