import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {BaseService} from './base.service';
import {DatakioskHttpService} from '../datakiosk-http.service';
import {User, UsersData} from '../model/users';
import {shareReplay} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class UsersService extends BaseService implements UsersData {
    constructor(http: DatakioskHttpService) {
        super(http);
        this.currentUser$ = this.http.get(`${this.apiEndpoint}`).pipe(shareReplay(1));
    }
    currentUser$: Observable<User>;

    get apiEndpoint(): string {
        return 'v2/users';
    }

    getCurrentUser(): Observable<User> {
        return this.currentUser$;
    }

    getApiKey(): Observable<string> {
        return this.http.get(`${this.apiEndpoint}/apikey`);
    }
}
