<div class="select mat-badge-{{getColor(selectedRole?.UserType)}}"
     [matMenuTriggerFor]="roleMenu"
     *ngIf="(roles$ | async)?.length > 1">
    <div class="mat-badge-content mat-badge-active circle">
        {{ selectedRole?.UserType.substring(0,1) }}
    </div>
    {{ selectedRole?.CustomerName}}
    <mat-icon>expand_more</mat-icon>
</div>
<mat-menu #roleMenu="matMenu" yPosition="below" class="role-menu nivus" (closed)="filterValue = ''">
    <mat-form-field (click)="stopPropagation($event)" (keydown)="stopPropagation($event)" class="search">
        <mat-label>Search...</mat-label>
        <input matInput type="text" [(ngModel)]="filterValue"/>
    </mat-form-field>
    <mat-action-list class="items">
        <mat-list-item *ngFor="let role of (roles$ | async) | filter: filterValue" (click)="selectRole(role)">
            <mat-icon
                mat-list-icon
                matBadge="{{role.UserType.substring(0,1)}}"
                matBadgeColor="{{getColor(role.UserType)}}"
                matBadgeOverlap="true"
                class="user-icon">
                business
            </mat-icon>
            <span matLine>{{role.CustomerName}}</span>
        </mat-list-item>
    </mat-action-list>
</mat-menu>
<ng-container *ngIf="(roles$ | async)?.length === 1">{{ selectedRole?.CustomerName}} ({{ selectedRole?.UserType}})</ng-container>
<ng-container *ngIf="(roles$ | async)?.length === 0">No role found</ng-container>
