<button [matMenuTriggerFor]="flags" mat-icon-button class="m-r-5">
    <i class="flag-icon flag-icon-{{selectedLanguage.icon}}"> </i>
</button>
<mat-menu #flags="matMenu" class="mymegamenu">
    <button mat-menu-item *ngFor="let lang of languages" (click)="changeLanguage(lang)">
        <i class="flag-icon flag-icon-{{lang.icon}} m-r-10"></i>
        <span>{{lang.language | translate}}</span>
    </button>
</mat-menu>
<button [matMenuTriggerFor]="profile" mat-button class="m-r-5" *ngIf="(authService.isLoggedIn$ | async)">
    <mat-icon>person</mat-icon> <span fxHide.lt-sm="true">{{authService.currentUserEmail$ | async}}</span>
</button>
<mat-menu #profile="matMenu" class="mymegamenu">
    <a mat-menu-item href="https://identity.nivusweb.com">
        <mat-icon>account_box</mat-icon>
        {{ 'PROFILE' | translate }}
    </a>
    <button mat-menu-item (click)="authService.logout()">
        <mat-icon>exit_to_app</mat-icon>
        {{ 'SIGN_OUT' | translate }}
    </button>
</mat-menu>
