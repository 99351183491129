import {AuthService} from "../../auth/auth.service";
import {Component} from '@angular/core';

@Component({
    selector: 'nivus-projects',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {

    constructor(
        public authService: AuthService) {
    }
}


